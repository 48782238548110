<template>
  <BaseSectionSearch @submit="onSubmit" @reset="onReset">
    <v-row dense>
      <FormItem
        v-model="name"
        type="text"
        name="Nome"
        hint="Ricerca per Nome"
        :cols="2"
        dense
      />
      <FormItem
        v-model="active"
        type="select"
        :values="[
          { value: 'true', text: 'Attivo' },
          { value: 'false', text: 'Inattivo' },
        ]"
        name="Stato"
        :cols="2"
        dense
      />

      <FormItem
        v-model="macros"
        :values="macrosByContext({ category: categories })"
        type="autocomplete"
        deletable-chips
        name="Limitati alle commesse"
        dense
        :cols="3"
      />

      <FormItem
        v-model="categories"
        :values="categoriesByContext({ macro: macros })"
        type="autocomplete"
        deletable-chips
        name="Limita alle categorie"
        dense
        :cols="3"
      />
    </v-row>
    <v-row dense>
      <FormItem
        v-model="strict"
        type="select"
        :values="[
          { value: false, text: 'Visibili al cliente' },
          { value: true, text: 'Solo associate' },
        ]"
        name="Abbinamento"
        :cols="2"
        dense
      />

      <FormCustomers
        v-model="customer_ids"
        name="Visibili al Cliente"
        dense
        clearable
        :cols="4"
      />

      <FormcustomersLocations
        v-model="customers_location_ids"
        name="Visibili per Sede"
        :customer_id="customer_ids"
        :disabled="!(customer_ids && customer_ids.length)"
        :cols="4"
        dense
        clearable
      />
    </v-row>
  </BaseSectionSearch>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapGetters, mapMutations, mapState } from 'vuex'

import BaseSectionSearch from '@components/structure/base-section-search.vue'
import FormItem from '@components/forms/FormItem.vue'
import FormCustomers from '@components/forms/FormCustomers.vue'
import FormcustomersLocations from '@components/forms/FormCustomersLocations.vue'

const { mapFields } = createHelpers({
  getterType: 'agendas/getFiltersFields',
  mutationType: 'agendas/SET_FILTER_FIELDS',
})

export default {
  name: 'AgendasSectionSearch',
  components: {
    FormcustomersLocations,
    FormCustomers,
    FormItem,
    BaseSectionSearch,
  },
  computed: {
    ...mapFields([
      'active',
      'macros',
      'categories',
      'name',
      'customer_ids',
      'customers_location_ids',
      'strict',
    ]),
    ...mapState('agendas', ['filters']),
    ...mapGetters('ticketsActivities', {
      macrosByContext: 'macrosByContext',
      categoriesByContext: 'categoriesByContext',
    }),
  },
  mounted() {
    this.reset()
  },
  methods: {
    ...mapMutations('agendas', {
      reset: 'RESET_FILTER_FIELDS',
    }),
    onSubmit() {
      this.$emit('search')
    },
    onReset() {
      this.reset()
      this.$emit('search')
    },
  },
}
</script>

<style scoped></style>
