<template>
  <div>
    <v-row>
      <v-col cols="4">
        <Form
          :hide-reset="true"
          :title="null"
          :loading="loading"
          @submit="onSubmit"
        >
          <h3>{{ agenda.name }}</h3>
          <p>Specifica un range temporale per generale nuovi slots.</p>
          <v-row dense>
            <FormItem
              v-model="generator.start"
              name="Data Inzio"
              type="datepicker"
              rules="required"
              :cols="12"
              :sm="12"
              :md="12"
              :lg="12"
              :xl="12"
            />
          </v-row>
          <v-row dense>
            <FormItem
              v-model="generator.end"
              name="Data Fine"
              type="datepicker"
              rules="required"
              :cols="12"
              :sm="12"
              :md="12"
              :lg="12"
              :xl="12"
            />
          </v-row>
          <v-row dense>
            <FormItem
              v-model.number="generator.availability"
              name="Disponibilità Max Slot"
              type="number"
              rules="required"
              :min="0"
              :max="100"
              :cols="12"
              :sm="6"
              :md="6"
              :lg="6"
              :xl="6"
            />
            <FormItem
              v-model="generator.include_holidays"
              name="Includi festività"
              type="switch"
              dense
              :cols="12"
              :sm="6"
              :md="6"
              :lg="6"
              :xl="6"
            />
          </v-row>
          <v-row dense>
            <FormItem
              v-model="agenda.duration"
              name="Durata predefinita"
              type="number"
              suffix="minuti"
              dense
              disabled
              :cols="12"
              :sm="12"
              :md="12"
              :lg="12"
              :xl="12"
            />
          </v-row>
        </Form>
      </v-col>
      <v-col>
        <SlotsCalendar
          ref="slotsCalendar"
          :today="today"
          :agenda-id="agenda.id"
          show-all
          @slot-click="onSlotClick"
        />
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card min-width="300px" max-width="350px" flat>
            <v-toolbar>
              <v-icon left>mdi-pencil</v-icon>
              <v-toolbar-title>Modifica Slot</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <Form hide-reset send-text="Aggiorna" @submit="onSlotUpdated">
                <p>
                  Slot del
                  <strong>
                    {{ slot.start | moment('DD/MM/YYYY HH:mm') }}
                  </strong>
                </p>
                <v-row dense>
                  <FormItem
                    v-model="availability"
                    name="Disponibilità Massima"
                    type="number"
                    dense
                    :cols="12"
                    :sm="12"
                    :md="12"
                    :lg="12"
                    :xl="12"
                  />
                </v-row>
                <v-row dense>
                  <FormItem
                    v-model="locked"
                    name="Blocca"
                    :cols="6"
                    type="switch"
                    dense
                  />
                </v-row>
              </Form>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { createHelpers } from 'vuex-map-fields'

import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'
import SlotsCalendar from '@components/agendas/SlotsCalendar.vue'

const { mapFields } = createHelpers({
  getterType: 'agendasSlots/getCurrent',
  mutationType: 'agendasSlots/SET_CURRENT_FIELDS',
})

export default {
  name: 'AgendasSlotsForm',
  components: {
    SlotsCalendar,
    FormItem,
    Form,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Crea Nuovo',
    },
  },
  data: () => ({
    today: null,
    generator: {
      start: null,
      end: null,
      availability: 1,
      include_holidays: false,
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
  }),
  computed: {
    ...mapState('agendas', {
      agenda: (state) => state.current,
    }),
    ...mapState('agendasSlots', {
      loading: (state) => state.loading,
      slot: (state) => state.current,
    }),
    ...mapFields(['availability', 'locked']),
  },
  mounted() {},
  methods: {
    ...mapMutations('agendasSlots', {
      reset: 'RESET_CURRENT',
      setCurrentValues: 'SET_CURRENT',
    }),
    ...mapActions('agendasSlots', ['create', 'update']),

    async onSubmit() {
      try {
        await this.create({
          ...this.generator,
          agenda_id: this.agenda.id,
        })
        this.$refs.slotsCalendar.loadEvents()
      } catch (err) {
        console.log('errore', err)
      }
    },
    onSlotClick({ nativeEvent, event }) {
      this.setCurrentValues(event)

      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },

    async onSlotUpdated() {
      await this.update()
      this.selectedOpen = false
      this.$refs.slotsCalendar.loadEvents()
    },
  },
}
</script>

<style scoped></style>
