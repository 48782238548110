<template>
  <v-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl">
    <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
      <v-autocomplete
        v-model="select"
        :chips="multi"
        :clearable="clearable"
        :deletable-chips="multi"
        :dense="dense"
        :disabled="disabled"
        :error-messages="errors"
        :filter="customFilter"
        :hint="hint"
        :items="items"
        :label="label || name"
        :loading="loading"
        :multiple="multi"
        :persistent-hint="persistentHint"
        :readonly="readonly"
        :search-input.sync="search"
        :small-chips="multi"
        hide-details
        filled
        @input="onInput"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              Non è stata trovata nessuna sede.
            </v-list-item-title>
          </v-list-item>
        </template>
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
            <v-list-item-subtitle v-text="item.address" />
            <v-list-item-subtitle v-text="item.city" />
          </v-list-item-content>
        </template>
      </v-autocomplete> </ValidationProvider
  ></v-col>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'
import { customFilterFromProps } from '@/src/utils/helper-functions'

export default {
  name: 'FormCustomersLocations',
  components: {},
  props: {
    clearable: {
      default: false,
      type: Boolean,
    },
    cols: {
      type: [String, Number],
      default: 12,
    },
    sm: {
      type: [String, Number],
      default: 6,
    },
    md: {
      type: [String, Number],
      default: 3,
    },
    lg: {
      type: [String, Number],
      default: 3,
    },
    xl: {
      type: [String, Number],
      default: 3,
    },
    customer_id: {
      required: true,
      type: [String, Array],
    },
    customers_location_ids: {
      default: null,
      type: [String, Array],
    },
    dense: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    hint: {
      default: null,
      required: false,
      type: String,
    },
    label: {
      default: null,
      required: false,
      type: String,
    },
    multi: {
      default: false,
      type: Boolean,
    },
    name: {
      default: 'Sedi',
      type: String,
    },
    persistentHint: {
      default: false,
      type: Boolean,
    },
    readonly: {
      default: false,
      type: Boolean,
    },
    required: {
      default: false,
      type: Boolean,
    },
    rules: {
      default: null,
      required: false,
      type: [String, Object],
    },
    value: {
      default: null,
      type: [String, Array],
    },
  },
  data: () => ({
    loading: false,
    search: null,
    select: null,
  }),
  computed: {
    ...mapGetters('customersLocations', {
      items: 'getListDropdown',
    }),
  },
  watch: {
    customer_id: {
      immediate: true,
      handler: 'prefill',
    },
    search(val) {
      val && val !== this.select && this.querySelections(val)
    },
  },
  beforeDestroy() {
    this.clearList()
  },
  methods: {
    ...mapActions('customersLocations', {
      searchLocations: 'getDropdownList',
    }),
    ...mapMutations('customersLocations', {
      clearList: 'RESET_LIST',
    }),
    async prefill() {
      if (
        this.customer_id ||
        (Array.isArray(this.customer_id) && this.customer_id.length)
      ) {
        this.loading = true

        await this.searchLocations({
          filters: {
            ...(Array.isArray(this.customer_id) &&
              this.customer_id.length && {
                id: this.customers_location_ids,
              }),
            customer_id: this.customer_id,
          },
        })

        this.select = this.customers_location_ids
        this.loading = false
      } else {
        this.clearList()
        this.select = this.multi ? [] : null
      }
    },
    customFilter: customFilterFromProps(['text', 'city', 'address']),
    async querySelections(query) {
      this.loading = true

      await this.searchLocations({
        filters: {
          query,
          customer_id: this.customer_id,
        },
      })
      this.loading = false
    },
    onInput: debounce(function(value) {
      this.$emit('input', value)
    }, 250),
  },
}
</script>
