<template>
  <Form
    :hide-reset="edit"
    :title="title"
    :loading="loading"
    @submit="onSubmit"
    @reset="onReset"
  >
    <v-row>
      <FormItem
        v-model="name"
        type="text"
        name="Nome Agenda"
        rules="required"
      />
      <FormItem v-model="active" type="switch" name="Abilitato" />
    </v-row>
    <v-row>
      <FormItem
        v-model="duration"
        type="number"
        name="Ampiezza Slot"
        suffix="min"
        rules="required|min_value:10"
        :min="10"
        :max="480"
        :lg="2"
        :xl="2"
      />
      <FormItem
        v-model="time_start"
        type="time"
        name="Inizio Fascia"
        rules="required"
        :lg="2"
        :xl="2"
      />
      <FormItem
        v-model="time_end"
        type="time"
        name="Fine Fascia"
        rules="required"
        :lg="2"
        :xl="2"
      />

      <FormItem
        v-model.number="slots_delay"
        type="number"
        name="Anticipo min. prenotazione"
        suffix="ore"
        rules="required|min_value:0"
        :lg="2"
        :xl="2"
      />

      <FormItem
        v-model="weekdays"
        type="select"
        :values="days"
        name="Giorni della settimana"
        rules="required"
        multiple
        :md="6"
        :lg="5"
        :xl="5"
      />
    </v-row>

    <v-divider class="my-6" />

    <v-row>
      <FormItem
        v-model="macros"
        :values="macrosByContext({ category: categories })"
        type="autocomplete"
        deletable-chips
        name="Limita alle commesse"
        :cols="12"
        :sm="12"
        :md="6"
        :lg="4"
        :xl="3"
        @input="updateCategories"
      />
      <FormItem
        v-model="categories"
        :values="categoriesByContext({ macro: macros })"
        :disabled="!macros.length"
        type="autocomplete"
        deletable-chips
        name="Limita alle categorie"
        :cols="12"
        :sm="12"
        :md="6"
        :lg="4"
        :xl="3"
      />
    </v-row>
    <v-row>
      <FormCustomers
        v-model="customer_ids"
        :customer_id="customer_ids"
        name="Visibili al Cliente"
        multi
        clearable
        :lg="4"
      />
      <FormCustomersLocations
        v-model="customers_location_ids"
        name="Visibili per Sede"
        multi
        clearable
        :customer_id="customer_ids"
        :customers_location_ids="customers_location_ids"
        :disabled="!customer_ids.length"
        :lg="4"
      />
    </v-row>

    <v-divider class="my-6" />

    <v-row>
      <v-col cols="12" :md="7" :lg="6" :xl="7">
        <ChipsSelect
          v-model="distribution_list"
          label="Notifiche Via Mail"
          hint="Inserici gli indirizzi ai quali verrà inviata una notifica in caso di nuovi ticket."
          :rules="{ required: false, email: true }"
        />
      </v-col>
    </v-row>
  </Form>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'
import FormCustomers from '@components/forms/FormCustomers.vue'
import FormCustomersLocations from '@components/forms/FormCustomersLocations.vue'
import ChipsSelect from '@components/forms/ChipsSelect.vue'

const { mapFields } = createHelpers({
  getterType: 'agendas/getCurrent',
  mutationType: 'agendas/SET_CURRENT_FIELDS',
})

export default {
  name: 'AgendasForm',
  components: {
    ChipsSelect,
    FormCustomersLocations,
    FormCustomers,
    FormItem,
    Form,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Crea Nuovo',
    },
  },
  data: () => ({
    days: [
      { value: 1, text: 'Lunedì' },
      { value: 2, text: 'Martedì' },
      { value: 3, text: 'Mercoledì' },
      { value: 4, text: 'Giovedì' },
      { value: 5, text: 'Venerdì' },
      { value: 6, text: 'Sabato' },
      { value: 7, text: 'Domenica' },
    ],
  }),
  computed: {
    ...mapFields([
      'name',
      'duration',
      'time_start',
      'time_end',
      'weekdays',
      'excluded',
      'slots_delay',
      'active',
      'macros',
      'categories',
      'customer_ids',
      'customers_location_ids',
      'distribution_list',
    ]),
    ...mapState('agendas', {
      id: (state) => state.current.id,
      loading: (state) => state.loading,
    }),
    ...mapGetters('ticketsActivities', {
      macrosByContext: 'macrosByContext',
      categoriesByContext: 'categoriesByContext',
    }),
  },
  mounted() {},
  methods: {
    ...mapMutations('agendas', {
      reset: 'RESET_CURRENT',
      setCurrentValues: 'SET_CURRENT',
    }),
    ...mapActions('agendas', [
      'create',
      'update',
      'setCurrent',
      'remove',
      'getItems',
    ]),
    async onSubmit() {
      try {
        this.$emit('submit')

        if (!this.edit) {
          await this.create()
        } else {
          await this.update()
        }

        this.$emit('submitted', this.id)
      } catch (err) {
        console.log('errore', err)
      }
    },
    onReset() {
      const current = this.edit ? this.id : null
      this.reset()
      if (current) this.setCurrent(current)
      this.$emit('resetted')
    },
    updateCategories(macros) {
      if (!macros.length) this.categories = []
    },
  },
}
</script>

<style scoped></style>
