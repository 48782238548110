<template>
  <v-container fluid class="px-lg-10">
    <AgendasSectionSearch @search="() => $refs.table.refresh()" />
    <BaseTable
      ref="table"
      title="Gestione Agende"
      module="agendas"
      :headers="headers"
      :actions="actions"
      sortby="name"
    >
      <template v-slot:actions>
        <v-btn
          v-if="canUser('agendas', 'create')"
          color="green"
          dark
          tile
          @click="openModal"
        >
          Aggiungi Agenda
        </v-btn>
      </template>
    </BaseTable>
    <v-dialog
      v-model="dialog"
      max-width="75%"
      :retain-focus="false"
      @click:outside="modalClose"
    >
      <v-card>
        <v-card-title>Dettagli Agenda</v-card-title>
        <v-card-text>
          <AgendasForm
            :title="null"
            :edit="editMode"
            @submitted="onSubmitted"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="slotsDialog"
      max-width="75%"
      :retain-focus="false"
      @click:outside="resetCurrentSlot"
    >
      <v-card>
        <v-card-title>Gestione Slot Agenda</v-card-title>
        <v-card-text>
          <AgendasSlotsForm v-if="slotsDialog" :title="null" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import BaseTable from '@components/structure/base-table.vue'

import { authComputed } from '@state/helpers.js'
import AgendasSectionSearch from '@components/agendas/AgendasSectionSearch.vue'
import AgendasForm from '@components/agendas/AgendasForm.vue'
import AgendasSlotsForm from '@components/agendas/AgendasSlotsForm.vue'

export default {
  name: 'AgendasSearch',
  page: {
    title: 'Cerca agende',
  },
  components: {
    AgendasSlotsForm,
    AgendasForm,
    AgendasSectionSearch,
    BaseTable,
  },
  data() {
    return {
      dialog: false,
      slotsDialog: false,
      editMode: false,
      headers: [
        {
          text: 'Nome Agenda',
          value: 'name',
        },

        {
          text: 'Commessa',
          value: 'macros',
        },
        {
          text: 'Categoria',
          value: 'categories',
        },
        {
          text: 'Stato',
          value: 'active',
        },
      ],
      actions: [
        {
          label: 'Modifica',
          icon: 'mdi-pencil',
          color: 'primary',
          enabled: true,
          onItemCondition: () => this.canUser('agendas', 'update'),
          handler: this.openModal,
        },
        {
          label: 'Genera Slot',
          icon: 'mdi-calendar',
          color: 'orange',
          enabled: true,
          onItemCondition: () => this.canUser('agendas_slots', 'create'),
          handler: this.onSlot,
        },
      ],
    }
  },
  computed: {
    ...authComputed,
  },
  methods: {
    ...mapActions('agendas', ['update', 'removeItem']),
    ...mapMutations('agendas', {
      setCurrent: 'SET_CURRENT',
      resetCurrent: 'RESET_CURRENT',
    }),
    ...mapMutations('agendasSlots', {
      resetCurrentSlot: 'RESET_CURRENT',
    }),
    openModal(item) {
      const startingPoint = item.id ? item : {}
      // Understand if the modal was opened as editing or for a new sub
      const isEditing = !!startingPoint.id
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialog = true
    },
    onSubmitted() {
      this.modalClose()
      this.$refs.table.refresh()
    },
    modalClose() {
      this.dialog = false
      this.slotsDialog = false
      this.editMode = false
      this.resetCurrent()
    },
    onSlot(item) {
      this.setCurrent(item)
      this.slotsDialog = true
    },
  },
}
</script>
