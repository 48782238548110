var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"name":_vm.label,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"filled":"","clearable":_vm.clearable,"dense":_vm.dense,"disabled":_vm.disabled,"error-messages":errors,"hide-no-data":!_vm.search,"hint":_vm.hint,"items":_vm.items,"label":_vm.finalLabel,"persistent-hint":_vm.persistentHint,"search-input":_vm.search,"value":_vm.value,"hide-selected":"","multiple":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"input":_vm.onInput},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('span',{staticClass:"subheading"},[_vm._v("Invio per aggiungere")]),_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.search)+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"small":_vm.dense}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("close")])],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }