<template>
  <ValidationProvider v-slot="{ errors }" :name="label" :rules="rules">
    <v-combobox
      filled
      :clearable="clearable"
      :dense="dense"
      :disabled="disabled"
      :error-messages="errors"
      :hide-no-data="!search"
      :hint="hint"
      :items="items"
      :label="finalLabel"
      :persistent-hint="persistentHint"
      :search-input.sync="search"
      :value="value"
      hide-selected
      multiple
      @input="onInput"
    >
      <template v-slot:no-data>
        <v-list-item>
          <span class="subheading">Invio per aggiungere</span>
          <v-chip label small>
            {{ search }}
          </v-chip>
        </v-list-item>
      </template>
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip v-bind="attrs" :input-value="selected" :small="dense">
          <span class="pr-2">
            {{ item }}
          </span>
          <v-icon small @click="parent.selectItem(item)">close</v-icon>
        </v-chip>
      </template>
    </v-combobox>
  </ValidationProvider>
</template>

<script>
import { trim, isString, isPlainObject } from 'lodash'
export default {
  name: 'ChipsSelect',
  props: {
    clearable: {
      default: false,
      required: false,
      type: Boolean,
    },
    dense: Boolean,
    disabled: {
      default: false,
      required: false,
      type: Boolean,
    },
    hint: {
      default: null,
      required: false,
      type: String,
    },
    label: {
      default: 'Gestione tags',
      required: false,
      type: String,
    },
    persistentHint: {
      default: false,
      type: Boolean,
    },
    rules: {
      default: null,
      required: false,
      type: [String, Object],
    },
    value: {
      default: () => [],
      type: Array,
    },
  },
  data: () => ({
    search: null,
  }),
  computed: {
    items() {
      return this.value || []
    },
    shouldDisplayAsterisk() {
      if (isString(this.rules)) return this.rules.includes('required')
      if (isPlainObject(this.rules)) return this.rules.required
      return false
    },
    finalLabel() {
      return trim(`${this.label} ${this.shouldDisplayAsterisk ? '*' : ''}`)
    },
  },
  methods: {
    onInput(val) {
      this.$emit('input', val)
    },
  },
}
</script>
