<template>
  <v-row>
    <v-col>
      <v-toolbar flat>
        <v-btn
          outlined
          class="mr-4"
          color="grey darken-2"
          small
          tile
          depressed
          @click="setToday"
        >
          Oggi
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small> mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu bottom right offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              outlined
              tile
              depressed
              color="grey darken-2"
              v-bind="attrs"
              v-on="on"
            >
              <span>{{ typeToLabel[type] }}</span>
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>Giorno</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'week'">
              <v-list-item-title>Settimana</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>Mese</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-sheet :height="height">
        <v-calendar
          ref="calendar"
          v-model="focus"
          :start="startingDate"
          :weekdays="weekdays"
          :events="events"
          :event-height="20"
          :first-interval="7"
          :interval-minutes="60"
          :interval-count="13"
          :interval-height="60"
          :type="type"
          event-overlap-mode="stack"
          disabled
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="onChange"
        />
      </v-sheet>
      <v-alert v-if="!events.length" type="info" dense prominent class="mt-3">
        Attenzione: Non ci sono slot disponibili nel range temporale
        selezionato.
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SlotsCalendar',
  props: {
    height: {
      type: [String, Number],
      required: false,
      default: 500,
    },
    agendaId: {
      type: [String, Number],
      required: false,
    },
    today: {
      type: [String, Object],
      required: false,
      default: null,
    },
    showAll: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    focus: '',
    type: 'week',
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    typeToLabel: {
      month: 'Mese',
      week: 'Settimana',
      day: 'Giorno',
    },
  }),
  computed: {
    ...mapGetters('agendasSlots', {
      events: 'getEvents',
    }),
    startingDate() {
      if (this.showAll) return

      return this.$moment().format('YYYY-MM-DD')
    },
  },
  watch: {
    agendaId: {
      immediate: true,
      handler: 'loadEvents',
    },
  },
  mounted() {
    this.$refs.calendar.checkChange()
    this.$refs.calendar.scrollToTime('09:00')

    if (this.$vuetify.breakpoint.smAndDown) this.type = 'day'
  },
  beforeDestroy() {
    this.resetEvents()
  },

  methods: {
    ...mapMutations('agendasSlots', {
      setFilters: 'SET_FILTER_FIELDS',
      setScopes: 'SET_SCOPES',
      resetEvents: 'RESET_LIST',
    }),
    ...mapActions('agendasSlots', {
      fetchEvents: 'getDropdownList',
    }),
    viewDay({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor(event) {
      return event.color
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    async onChange({ start, end }) {
      ;[
        ['start', { start: `${start.date} 00:00` }],
        ['end', { end: `${end.date} 23:59` }],
        ['agenda_id', this.agendaId],
        ['show_all', this.showAll],
      ].forEach(([path, value]) => this.setFilters({ path, value }))
      await this.loadEvents()
    },
    async loadEvents() {
      if (!this.agendaId) {
        this.resetEvents()
        return
      }

      this.setFilters({
        path: 'agenda_id',
        value: this.agendaId,
      })

      await this.fetchEvents()
    },
    showEvent({ nativeEvent, event }) {
      this.$emit('slot-click', { nativeEvent, event })
    },
  },
}
</script>
